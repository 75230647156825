import {HmsLocalNotification} from '@hmscore/ionic-native-hms-push'

import bus from '@/plugins/bus'

const instance = {

    init() {
        //подписываемся на событие необходимости показа локального уведомления
        bus.on('SBS_NOTIFICATION_E_SHOW', async (data) => {

            let arNotification = {
                title: data.title,
                message: data.body,
                smallIcon: "fcm_push_icon",
                data: data,
            };
            if(data.image) {
                arNotification.largeIconUrl = data.image;
            }

            //если передали ИД канала
            if(data.channel_id) {
                //создаём канал
                arNotification.channelId = data.channel_id;
                arNotification.channelName = "";
                if(data.channel_id == "requests") {
                    arNotification.channelName = "Заявки";
                } else if (data.channel_id == "education") {
                    arNotification.channelName = "Обучение";
                }
            } else {
                //канал по умолчанию (который и так создаёт посстоянно плагин local-notifications)
                //просто переименовываем его
                //p.s по сути не даёт поменять название уже созданного канала (пока оставим код)
                arNotification.channelId = "default";
                arNotification.channelName = "Другое";
            }

            HmsLocalNotification.localNotification(arNotification);
        });

        //клик по локальному уведомлению (фактически никогда не вызывается, всегда вызывается onNotificationOpenedApp)
        window.HmsPushEvent.onLocalNotificationAction((result) => {
            console.log(JSON.stringify(result));
        });
    }

}

export default instance;