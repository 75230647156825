<template>
  <sbs-control-input v-model="value" type="text" :label="label" :name="name" :required="required" :readonly="true"
    :classes="['date']" @click="onClick()">
    <template v-slot:icon>
      <div v-if="value == ''" class="icon">
        <span class="fi fi-date"></span>
      </div>
      <div v-else class="icon" @click.stop="clear">
        <span class="fi fi-close"></span>
      </div>
    </template>
  </sbs-control-input>

  <sbs-sheet v-model:visible="datePickerOpen" :onlyHeaderSwipe="true">
    <template v-slot:body>
      <div class="sbs-picker-date">
        <div class="pickers">
          <VueScrollPicker :options="days" v-model="day" />
          <VueScrollPicker :options="months" v-model="month" />
          <VueScrollPicker :options="years" v-model="year" />
        </div>
        <div class="buttons">
          <div class="btn btn1" @click="apply"><span class="fi fi-save-bold"></span></div>
          <div class="btn btn2" @click="clear"><span class="fi fi-close"></span></div>
        </div>
      </div>
    </template>
  </sbs-sheet>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsSheet from '@/components/sheet.vue'
import { VueScrollPicker } from "vue-scroll-picker";

import moment from "moment";

import "vue-scroll-picker/lib/style.css";

export default {
  name: "sbs-control-date",
  components: {
    sbsControlInput,
    sbsSheet,
    VueScrollPicker,
  },
  data() {
    return {
      value: this.modelValue,
      datePickerOpen: false,
      year: this.getDefaultYear(),
      month: this.getDefaultMonth(),
      day: this.getDefaultDay(),
      ignore: true,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },

    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    years() {
      const currYear = new Date().getFullYear() + 2;
      const lastYear = 1980;
      return Array.from(
        { length: currYear - lastYear + 1 },
        (_, index) => lastYear + index
      ).reverse();
    },
    months() {
      let options = [];
      for (var i = 1; i <= 12; i++) {
        options.push({
          value: i,
          name: moment([this.year, i - 1]).format("MMMM"),
        });
      }
      return options;
    },
    days() {
      const lastDay = new Date(this.year, this.month, 0).getDate();
      return Array.from({ length: lastDay }, (_, index) =>
        ("0" + (index + 1)).slice(-2)
      );
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
      }
    },
    value: function () {
      this.$emit("update:modelValue", this.value);
    },
  },
  methods: {
    onClick() {
      this.datePickerOpen = true;
    },

    setValue() {
      this.value =
        ("0" + this.day).slice(-2) +
        "." +
        ("0" + this.month).slice(-2) +
        "." +
        this.year;
    },

    getDefaultYear() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "DD.MM.YYYY").format("YYYY");
      }
      return new Date().getFullYear();
    },

    getDefaultMonth() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "DD.MM.YYYY").format("MM");
      }
      return new Date().getMonth() + 1;
    },

    getDefaultDay() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "DD.MM.YYYY").format("DD");
      }
      return ("0" + new Date().getDate()).slice(-2);
    },

    clear() {
      this.value = "";
      this.datePickerOpen = false;
    },

    apply() {
      this.setValue();
      this.datePickerOpen = false;
    }
  },

  created() {
    this.ignore = false;
  },
};
</script>

<style lang="scss">
.sbs-picker-date {


  .pickers {
    display: flex;
  }

  .vue-scroll-picker {
    height: 170px;
  }

  .vue-scroll-picker-item {
    color: var(--color-block-label);
    font-size: 20px;
    padding: 8px 0px;
    transform: scale(0.8);
  }

  .vue-scroll-picker-item-selected {
    color: var(--color-text-primary);
    transform: scale(1);
  }

  .vue-scroll-picker-layer-bottom {
    border-top: none;
    background: linear-gradient(to top,
        var(--color-block-background) 0%,
        transparent 26%);
    height: calc(50% - 20px);
  }

  .vue-scroll-picker-layer-top {
    border-bottom: none;
    background: linear-gradient(to bottom,
        var(--color-block-background) 0%,
        transparent 26%);
    height: calc(50% - 20px);
  }

  .vue-scroll-picker-layer-selection {
    top: calc(50% - 20px);
    bottom: calc(50% - 20px);

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 10px;
      right: 10px;
      height: 1px;
      background: var(--color-block-label);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 10px;
      right: 10px;
      height: 1px;
      background: var(--color-block-label);
    }
  }

  .buttons {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    .btn:first-of-type {
      margin-right: 20px;
    }
  }
}
</style>
