<template>
    <div class="sbs-edu-post-post">
        <div class="picture-wrap" v-if="item.picture">
            <div class="picture preloaded" v-lazy:background-image="item.picture.preview"
                @click="openPhoto(item.picture.id)">

                <div class="preload" v-if="item.picture.preload"
                    :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
                </div>

                <div class="loader">
                    <sbs-loader-inline />
                </div>
            </div>
        </div>

        <div class="author-date">
            <div class="date">{{ formatDate() }}</div>
            <div class="author">
                <div>
                    <div class="avatar">
                        <div v-if="!item.user_photo" class="initials">
                            {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                        </div>
                        <div v-else class="photo" :style="{
            backgroundImage: 'url(' + item.user_photo.preview + ')',
        }"></div>
                    </div>
                </div>
                <div>
                    <div class="fio">
                        {{ item.user_last_name }} {{ item.user_name }}
                    </div>
                </div>
            </div>
        </div>

        <h1>{{ item.name }}</h1>

        <div class="post-block" v-for="block in blocks" :key="block.id">

            <template v-if="block.type == 'image'">

                <div class="picture preloaded" v-lazy:background-image="block.image.preview"
                    @click="openPhoto(block.image.id)">

                    <div class="preload" v-if="block.image.preload"
                        :style="{ backgroundImage: 'url(\'' + block.image.preload + '\')' }">
                    </div>

                    <div class="loader">
                        <sbs-loader-inline />
                    </div>
                </div>
                <div class="description" v-if="block.title" v-html="block.title"></div>

            </template>

            <template v-if="block.type == 'video'">

                <div class="video-wrap">
                    <video :poster="block.video.preview" controls>
                        <source :src="block.video.src" type="video/mp4" />
                    </video>
                </div>

                <div class="description" v-if="block.title" v-html="block.title"></div>

            </template>

            <template v-else-if="block.type == 'text'">
                <div class="text" v-html="prepareText(block.text)" @click="onTextClick"></div>
            </template>

            <template v-else-if="block.type == 'video.link'">

                <div class="video-wrap" v-if="block.video && block.video.url">
                    <iframe :src="getVideoSrc(block.video)" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

                <div class="description" v-if="block.title" v-html="block.title"></div>

            </template>

        </div>

        <template v-if="trainingId <= 0">
            <div class="stat">
                <sbs-education-post-item-stat :item="item" />
            </div>
        </template>

        <template v-if="trainingId > 0">
            <sbs-teleport-wrapper :to="'#' + this.footerId">
                <div class="sbs-edu-post-post-btns">
                    <div class="container">
                        <div class="btn btn1 btn-block" @click="onNextClick" :class="saving ? 'wait' : ''" v-ripple.300>
                            {{ $t("common.next") }}
                            <div v-if="saving" class="btn-loader"></div>
                        </div>
                    </div>
                </div>
            </sbs-teleport-wrapper>
        </template>

    </div>

    <template v-if="item.comments_enabled == 'Y'">
        <br />
        <sbs-education-post-comments :postId="item.id" :private="item.comments_private == 'Y'" />
    </template>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsEducationPostComments from "@/components/education/post.comments.vue";
import sbsEducationPostItemStat from "@/components/education/post.item.stat.vue";

import moment from "moment";
import utils from "@/plugins/utils";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-post",
    components: {
        sbsLoaderInline,
        sbsTeleportWrapper,
        sbsEducationPostComments,
        sbsEducationPostItemStat
    },
    emits: ["complete"],
    data() {
        return {
            saving: false,
            videoTypes: [
                {
                    code: "youtube",
                    src: "https://www.youtube.com/embed/#VIDEO_ID#?feature=oembed",
                    getSrc: function (id) {
                        return this.src.replace("#VIDEO_ID#", id)
                    }
                },
                {
                    code: "rutube",
                    src: "https://rutube.ru/play/embed/#VIDEO_ID#",
                    getSrc: function (id) {
                        let parts = id.split("_");
                        let vid = parts[0];
                        parts.shift();

                        return this.src.replace("#VIDEO_ID#", vid) + (parts.length > 0 ? "?p=" + parts.join("_") : "")
                    }
                },
                {
                    code: "vkvideo",
                    src: "https://vk.com/video_ext.php?oid=-#OID#&id=#ID#&hd=2",
                    getSrc: function (id) {
                        let parts = id.split("_");
                        return this.src.replace("#OID#", parts[0]).replace("#ID#", parts[1]);
                    }
                }

            ],
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        footerId: {
            type: String,
            default() {
                return ""
            }
        }
    },

    computed: {
        ...mapGetters({
            server: "getServer",
            user: "getUserProfile",
        }),

        blocks() {
            if (!this.item.data || !this.item.data.blocks) {
                return [];
            }

            return this.item.data.blocks;
        },

        gallery() {
            let images = [];

            if (this.item.picture.preview) {
                images.push({
                    src: this.item.picture.full ? this.item.picture.full : this.item.picture.src,
                    type: "image",
                    width: this.item.picture.width,
                    height: this.item.picture.height,
                    thumb: this.item.picture.preview ? this.item.picture.preview : this.item.picture.src,
                    caption: this.item.name,
                    id: this.item.picture.id,
                });
            }

            this.blocks.forEach((block) => {
                if (block.type == 'image') {
                    if (block.image) {
                        images.push({
                            src: block.image.full ? block.image.full : block.image.src,
                            type: "image",
                            width: block.image.width,
                            height: block.image.height,
                            thumb: block.image.preview ? block.image.preview : block.image.src,
                            caption: block.title,
                            id: block.image.id,
                        });
                    }
                } else if (block.type == 'video.link') {
                    if (block.video) {
                        images.push({
                            src: this.getVideoSrc(block.video),
                            type: "video",
                            caption: block.title,
                            id: block.id,
                        });
                    }
                }
            });


            return images;
        },
    },

    methods: {

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Клик по тексту
         */
        onTextClick(e) {
            //если клик по ссылке
            if (e.target.tagName == "A") {

                let url = e.target.getAttribute('href');
                //получим относительную ссылку
                url = url.replace("http://" + this.server, "").replace("https://" + this.server, "");
                this.routeClick(e, url);
            }

        },

        prepareText(text) {

            return text.replace("<span class=\"username\">﻿<span contenteditable=\"false\"></span>﻿</span>", this.user.name + ' ' + this.user.last_name);
        },

        /**
         * Маршрутизация клика по ссылке
         */
        routeClick(e, url) {

            let query = this.getUrlQuery(url);

            //куда-то в обучение
            if (url.startsWith("/education")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }

        },

        /**
         * Получает параметры из URL
         */
        getUrlQuery(url) {
            //парсим параметры (например переход к странице поиска)
            const [, paramString] = url.split('?');
            let urlParams = new URLSearchParams(paramString);
            let entries = urlParams.entries();
            let query = {};
            for (let [key, value] of entries) {
                query[key] = value;
            }

            return query;
        },

        /**
         * Получает ссылку для отображения видео
         */
        getVideoSrc(video) {
            let type = this.videoTypes.find(t => t.code == video.type);
            if (type) {
                return type.getSrc(video.id);
            }

            return "";
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },

        /**
         * Клик Далее - в режиме тренировки
         */
        onNextClick() {
            if (this.saving) {
                return;
            }

            this.saving = true;

            let fields = {
                id: this.item.id,
                training_id: this.trainingId,
                status: "S",
                data: {}
            };

            //сохраняем результат
            rest
                .call("education.post.result", {
                    method: "post",
                    data: fields,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {

                        //генерим событие успешного сохранения результата
                        bus.emit("SBS_EDU_POST_RESULT_E_SAVE", fields);
                        this.$emit("complete");
                    }
                }).finally(() => {
                    this.saving = false;
                });


        },

    },
};
</script>

<style lang="scss">
.sbs-edu-post-post {
    position: relative;

    h1 {
        color: var(--color-text-primary);
        text-transform: none;
        font-size: 21px;
        font-weight: 500;
    }

    @media (max-width: 767px) {
        .picture-wrap {
            margin: 0px -20px;
        }

    }

    .picture {
        height: 0px;
        padding-top: 60%;
        background-size: cover;
        background-position: center center;
        margin-bottom: 10px;
        background-color: var(--color-block-background);
    }

    .author-date {
        margin-bottom: 10px;

        .date {
            font-size: 12px;
            color: var(--color-chat-fio);
            float: right;
        }
    }

    .author {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .avatar {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--color-chat-fio);

            .initials {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-chat-background);
            }

            .photo {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-size: cover;
            }
        }

        .fio {
            font-size: 12px;
            font-weight: 500;
            color: var(--color-chat-fio);
        }


    }

    .post-block {
        margin-bottom: 20px;

        .description {
            font-size: 12px;
            color: var(--color-block-label);
            margin-bottom: 10px;
        }

        .text {
            font-size: 16px;
        }

        .video-wrap {

            position: relative;
            height: 0px;
            padding-top: 56.25%;

            background-color: var(--color-block-background);

            margin-bottom: 10px;

            iframe {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }

            video {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }



        }

    }
}

.sbs-edu-post-post-btns {
    padding: 15px 0px;

}
</style>