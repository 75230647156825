<template>
    <sbs-view :class="'sbs-v-edu-posts-filter'" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">

        <template v-slot:header>
            <sbs-header />
        </template>

        <template v-slot:content>

            <div class="h1">{{ $t('common.filter') }}</div>

            <template v-if="loading">
                <sbs-loader-inline />
            </template>

            <template v-else>

                <div class="filters">

                    <sbs-control-filter :label="$t('education.posts.filter.type')" :items="types"
                        v-model="filter.type" />

                    <sbs-control-filter :label="$t('education.posts.filter.author')" :items="authors"
                        v-model="filter.created_by" />

                    <sbs-control-filter :label="$t('education.posts.filter.created_department')"
                        :items="createdDepartments" v-model="filter.created_department_id" />

                    <sbs-control-filter :label="$t('education.posts.filter.tags')" :items="tags"
                        v-model="filter.tag_id" />

                    <sbs-control-filter-dates :label="$t('education.posts.filter.publication_date')"
                        v-model="filter.publication_date" />



                </div>

            </template>

        </template>

        <template v-slot:footer>
            <div class="buttons">
                <div class="container">
                    <div class="page-padding">
                        <div class="row">
                            <div class="col-6">
                                <div class="btn btn-block btn2" @click="clear">{{ $t('common.clear') }}</div>
                            </div>
                            <div class="col-6">
                                <div class="btn btn-block btn1" @click="apply">{{ $t('common.apply') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlFilter from "@/components/controls/filter";
import sbsControlFilterDates from "@/components/controls/filter.dates";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-education-posts-filter",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsControlFilter,
        sbsControlFilterDates
    },
    data() {
        return {
            loading: false,
            types: [],
            authors: [],
            createdDepartments: [],
            publicationDepartments: [],
            tags: [],

            filter: {},
            hideMenu: false,
            key: "EDUCATION_POSTS"
        };
    },

    computed: {
        globalFilter() {
            return this.$store.getters.getFilter(this.key);
        },
    },

    methods: {

        getBatchRequests() {
            return {
                types: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: ["type", "cnt"],
                        group: ["type"],
                        order: { "type": "asc" }

                    },
                },
                authors: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { 0: "cnt", 1: "created_by", 2: "user_name", 3: "user_last_name" },
                        group: ["created_by"],
                        order: { "CREATED_BY_USER.LAST_NAME": "asc" }
                    },
                },
                created_departments: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { "department_name": "created_department.name", 0: "cnt", 1: "created_department_id" },
                        group: ["created_department_id"],
                        order: { "created_department.name": "asc" }
                    },
                },
                publication_departments: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { "department_name": "publication_department_name", 0: "cnt", "department_id": "publication_department_id" },
                        group: ["publication_department_id"],
                        order: { "publication_department_name": "asc" },
                        filter: {
                            ">=publication_department_id": 0
                        }
                    },
                },
                tags: {
                    ENTITY: "education",
                    METHOD: "post.statistics",
                    PARAMS: {
                        select: { "tag_name": "tag.name", 0: "tag_id", 1: "cnt", },
                        group: ["tag_id"],
                        order: { "tag.name": "asc" },
                        filter: {
                            ">tag_id": 0
                        }
                    },
                },
            };

        },

        /**
         * Загрузка данных
         */
        loadData() {

            this.loading = true;

            rest.batch(this.getBatchRequests())
                .then((data) => {
                    this.processResponse(data);
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
         * Обработка результата загрузки
         */
        processResponse(results) {

            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "types") {
                        this.setTypes(data.items);
                    } else if (key == "authors") {
                        this.setAuthors(data.items);
                    } else if (key == "created_departments") {
                        this.setCreatedDepartments(data.items);
                    } else if (key == "publication_departments") {
                        this.setPublicationDepartments(data.items);
                    } else if (key == "tags") {
                        this.setTags(data.items);
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        /**
         * Устанавилвает список типов по статистике использования
         */
        setTypes(items) {
            this.types = items.map(item => {
                return { id: item.type, name: this.$t('education.posts.filter.types.' + item.type), count: item.cnt }
            });
        },

        /**
         * Устанавилвает список пользователей-авторов
         */
        setAuthors(items) {
            this.authors = items.map(item => {
                return { id: item.created_by, name: item.user_last_name + " " + item.user_name, count: item.cnt }
            });
        },

        /**
         * Устанавилвает список подраздлелений, создававших посты
         */
        setCreatedDepartments(items) {
            this.createdDepartments = items.map(item => {
                return { id: item.created_department_id, name: item.department_name, count: item.cnt }
            });
        },

        /**
         * Устанавилвает список подраздлелений, в которых назначена публикация
         */
        setPublicationDepartments(items) {
            this.publicationDepartments = items.map(item => {
                return { id: item.department_id, name: item.department_name ? item.department_name : this.$t('education.posts.filter.all'), count: item.cnt }
            });
        },

        /**
         * Устанавилвает список тегов
         */
        setTags(items) {
            this.tags = items.map(item => {
                return { id: item.tag_id, name: item.tag_name, count: item.cnt }
            });
        },

        setDefaults() {
            this.filter = {
                type: [],
                created_by: [],
                created_department_id: [],
                publication_department_id: [],
                publication_date: [],
                tag_id: []
            };
        },

        apply() {
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: this.key,
                value: this.filter
            });

            //переход на предыдущий экран
            this.$router.go(-1);
        },

        /**
         * Сбросить фильтр
         */
        clear() {
            //сбрасываем фильтр
            this.setDefaults();
            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", { name: this.key, value: this.filter });
            //переход на предыдущий экран
            this.$router.go(-1);
        },

        /**
         * Устанавливает значения из глобального фильтра
         */
        setFromGlobalFilter() {
            for (let code in this.filter) {
                if (this.globalFilter[code]) {
                    this.filter[code] = this.globalFilter[code];
                } else {
                    this.filter[code] = this.filter[code] instanceof Array ? [] : "";
                }
            }
        },
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.setDefaults();
        this.setFromGlobalFilter();
        this.loadData();
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.setFromGlobalFilter();
    },

    /**
     * Вызывается перед переходом в этот экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setFromGlobalFilter();
        });
    },
};
</script>

<style lang="scss">
.sbs-v-edu-posts-filter {

    .buttons {
        padding: 20px 0px;
    }

}
</style>