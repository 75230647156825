<template>

    <div class="sbs-edu-post-comments" ref="root">

        <h2>
            <template v-if="!this.private">
                {{ $t("education.post.comments.title") }}
            </template>
            <template v-else>
                {{ $t("education.post.comments.title_private") }}
            </template>

        </h2>

        <template v-if="!loading && items.length == 0">
            {{ $t("education.post.comments.empty") }}
            <br /><br /><br />
        </template>

        <div class="items">
            <template v-for="item in items" :key="item.id">
                <sbs-education-post-comment-item :item="item" class="item" @retry="retry(item)" />
            </template>
        </div>

        <div ref="pager"></div>

        <!-- Загрузка -->
        <template v-if="loading">
            <div class="text-center">
                <sbs-loader-inline />
            </div>
        </template>

        <div class="writer" :style="{ bottom: showWriter ? '0px' : '-150px' }">
            <div class="message-outer">

                <div class="message-wrap">
                    <textarea class="form-control" v-model="message" @input="handleInputMessage" rows="1"
                        :placeholder="$t('education.post.comments.write')" maxlength="2000"></textarea>
                    <div class="replacer form-control" v-html="message"></div>
                </div>
                <!-- отправить-->
                <div v-if="message" class="send" @click="send">
                    <span class="icon fi fi-send"></span>
                </div>
            </div>

            <div class="actions">
                <!-- Открыть камеру-->
                <div class="action" @click="getPicture">
                    <span class="icon fi fi-camera"></span>
                </div>
                <!-- Выбрать из медиабиблиотеки-->
                <div class="action" @click="getPictureFromLibrary">
                    <span class="icon fi fi-photo-library"></span>
                </div>
                <!-- Распознование-->
                <div class="action" v-if="isNativePlatform" @click="speech">
                    <span class="icon fi fi-mic"></span>
                </div>
            </div>

            <div class="photos" v-if="photos.length > 0">

                <div class="photo" v-for="photo in photos" :key="photo.id"
                    :style="{ backgroundImage: 'url(' + photo.data + ')' }">
                    <div class="delete" @click="deletePhoto(photo.id)">
                        <span class="ic fi fi-close"></span>
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostCommentItem from "@/components/education/post.comment.item.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import resizebase64 from "@/plugins/resizebase64";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-comments",
    components: {
        sbsLoaderInline,
        sbsEducationPostCommentItem
    },
    data() {
        return {
            loaded: false,
            loading: false,

            items: [],
            pager: {
                size: 15,
                page: 1,
                cnt: 1,
            },

            message: "",
            photos: [],
            writerHeight: 45,
            writerResizeObsover: false,
            showWriter: false
        }
    },
    props: {
        postId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        private: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            user: "getUserProfile",
            isNativePlatform: "isNativePlatform",
        }),

    },
    watch: {


    },

    methods: {


        /**
         * Загрузка данных 
         */
        loadData(resolve = false) {

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            let filter = {
                post_id: this.postId
            };
            if (this.private) {
                filter.user_id = "MY_SELF"
            }

            //запрашиваем данные
            rest
                .call("education.post.comment.list", {
                    method: "post",
                    data: {
                        paging: this.pager,
                        filter: filter,
                        load_my_reactions: "Y",
                        load_reactions: "Y"
                    },
                })
                .then((data) => {
                    if (data.success) {
                        if (this.pager.page == 1) {
                            this.items = data.items;
                        } else {
                            this.items = this.items.concat(data.items);
                        }
                        this.pager = data.pager;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });

        },


        /**
         * Обработка изменения значения в поле ввода сообщения
         * а то v-model на мобильнике раотает не корректно см.
         * https://github.com/vuejs/vue/issues/9777
         */
        handleInputMessage(event) {
            this.message = event.target.value
        },


        /**
         * Отправить сообщение
         */
        send() {
            //если ничего не ввели
            if (this.message.trim() == "") {
                return;
            }

            //доабвляем сообщение в чат
            let item = {
                id: moment().format("x"),
                comment: this.message,
                user_id: this.user.id,
                user_name: this.user.name,
                user_last_name: this.user.last_name,
                date: moment().format("DD.MM.YYYY HH:mm:ss"),
                saving: true,
                files: []
            };
            this.photos.forEach(photo => {
                item.files.push({
                    image: true,
                    name: "",
                    src: photo.data,
                    preview: photo.data,
                    width: photo.width,
                    height: photo.height,
                    id: photo.id,
                    photoData: photo.data.replace("data:image/jpeg;base64,", ""),
                });
            })

            //сброс набранного текста
            this.message = "";
            this.photos = [];
            //помещаем сообщение в начало списка
            this.items.unshift(item);

            //прокрутим список до вставленного комментария
            let y = this.$refs.root.offsetTop;
            this.$refs.pager
                .closest(".view-content-scroll").scrollTo({ top: y, behavior: 'smooth' });

            this.save(item);

        },

        /**
         * Сохранение сообщения
         */
        save(item) {
            //поля для отправки
            let fields = {
                post_id: this.postId,
                comment: item.comment,
                files: []
            };

            //добавлям файлы
            if (item.files) {
                item.files.forEach(file => {
                    if (file.photoData) {
                        fields.files.push(file.photoData);
                    }
                })

            }

            let formData = new FormData();
            for (let code in fields) {
                if (fields[code] instanceof Array) {
                    for (let i = 0; i < fields[code].length; i++) {
                        formData.append(code + "[]", fields[code][i]);
                    }
                } else {
                    formData.append(code, fields[code]);
                }

            }

            //сохранение сообщения
            rest
                .call("education.post.comment.add", {
                    method: "POST",
                    data: formData,
                    timeout: 0,
                })
                .then((data) => {
                    if (data.success) {
                        this.setItemSaveStatus(item.id, "success");
                        //если требуется синхронизировать добавляемое сообщение с сохранённым на сервере сообщением
                        this.syncItem(data.id, item.id)


                    } else {
                        this.setItemSaveStatus(item.id, "error");
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                });
        },

        /**
         * Устанавилвает сообщению статус сохранения
         */
        setItemSaveStatus(id, status) {
            let item = this.items.find((i) => i.id == id);
            if (item) {
                item.saving = false;
                item.status = status;
            }
        },

        /**
         * Синхронизирует сообщение
         */
        syncItem(id, targetId) {
            //запрашиваем данные
            rest
                .call("education.post.comment.list", {
                    method: "post",
                    data: {
                        filter: {
                            post_id: this.postId,
                            id: id,
                        },
                    },
                })
                .then((data) => {
                    if (data.success && data.items.length > 0) {
                        let item = data.items[0];
                        let index = this.items.findIndex(i => i.id == targetId);
                        this.items[index] = item;
                    }
                });
        },

        /**
         * Обработка срола списка
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (!this.loading && this.pager.page < this.pager.cnt) {
                    this.pager.page++;
                    this.loadData();
                }
            }


            let offsetTopRoot = this.$refs.root.offsetTop;
            if (scroll + height > offsetTopRoot + 200) {
                this.showWriter = true;
            } else {
                this.showWriter = false;
            }
        },

        /**
         * Получить фото с камеры
         */
        getPicture() {
            bus.emit("SBS_CAMERA_E_GET_PICTURE", {
                resolve: this.onGetPicture,
            });
        },

        /**
         * Получить фото с медиабиблиотеки
         */
        getPictureFromLibrary() {
            bus.emit("SBS_CAMERA_E_GET_PICTURE", {
                resolve: this.onGetPicture,
                photoLibrary: true,
            });
        },

        /**
         * Рапознование голоса для вставки текста сообщения
         */
        speech() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.message = text;
                },
            });
        },

        /**
         * Обработка получения фото с камеры
         */
        async onGetPicture(imageData) {

            var newImg = await resizebase64(
                "data:image/jpeg;base64," + imageData,
                1200,
                1200
            );

            let sizes = await this.getImageBase64Sizes(newImg);

            this.photos.push({
                id: moment().format("x"),
                width: sizes.width,
                height: sizes.height,
                data: newImg,
            });

        },

        /**
         * Получает по указанному файлу-картинке размеры
         */
        getImageBase64Sizes(data) {
            let img = new Image();

            let promise = new Promise((resolve, reject) => {
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = reject;
            });
            img.src = data;

            return promise;
        },

        /**
         * Попытка отправить повторно
         */
        retry(item) {
            this.save(item);
        },

        /**
         * Удаление фото из списка на отправку в сообщении
         */
        deletePhoto(id) {
            this.photos = this.photos.filter((p) => p.id != id);
        }


    },

    /**
     * Событие создания экземпляра
     */
    created() {
        this.loadData();
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {

        //отписывается от скролинга экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    }


}

</script>
<style lang="scss">
.sbs-edu-post-comments {


    .writer {
        position: sticky;
        bottom: 0px;

        transition: bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1);



        margin: 0px -20px;
        padding: 10px;
        /*padding-bottom: calc(var(--keyboard-height, 0px) + var(--safe-area-bottom, 0px) + 10px);*/

        background-color: var(--color-background);

        .message-outer {
            position: relative;
        }

        .message-wrap {
            /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
            display: grid;

            .replacer {
                /* This is how textarea text behaves */
                white-space: pre-wrap;
                /* Hidden from view, clicks, and screen readers */
                visibility: hidden;
            }

            textarea {
                /* You could leave this, but after a user resizes, then it ruins the auto sizing */
                resize: none;
                /* Firefox shows scrollbar on growth, you can hide like this. */
                overflow: hidden;
            }

            textarea,
            .replacer {
                /* Place on top of each other */
                grid-area: 1 / 1 / 2 / 2;
                max-height: 150px;
                padding-right: 44px;
            }
        }

        .actions {
            &:after {
                content: "";
                display: block;
                clear: both;
            }

            .action {
                position: relative;
                float: left;
                width: 44px;
                height: 44px;
                overflow: hidden;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 18px;
                    color: var(--color-color1);
                }
            }

            .add-file {
                input[type="file"] {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    transform: translate(-300px, 0) scale(4);
                    font-size: 23px;
                    direction: ltr;
                    cursor: pointer;
                    z-index: 2;
                }
            }
        }

        .send {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 44px;
            height: 44px;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 18px;
                color: var(--color-color1);
            }
        }

        .photos {
            margin-top: 20px;

            &::after {
                content: "";
                display: block;
                clear: both;
            }

            .photo {
                position: relative;
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                width: 76px;
                height: 76px;

                background-color: #000;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;

                .delete {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: var(--color-color1);

                    .ic {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: var(--color-btn1-text);
                        font-size: 13px;
                    }
                }
            }
        }


    }

}
</style>