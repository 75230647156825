const enums = {
    fieldTypes:  [
        {
            id: "STRING",
            name: "Строка",
        },
        {
            id: "NUMBER",
            name: "Число",
        },
        {
            id: "LIST",
            name: "Выбор из списка",
        },
        {
            id: "DATE",
            name: "Дата",
        },
    ],

    entities: [
        {
            id: "EQUIPMENT",
            name: "Оборудование",
        },
        {
            id: "REQUEST",
            name: "Заявка",
        },
    ],

    services: [
        {
          id: "REQUESTS",
          name: "Заявки"
        },
        {
          id: "EDUCATION",
          name: "Обучение"
        }
    ],
    
}

export default enums;