<template>
    <div class="sbs-control-reaction" @click.stop>
        <div class="like" :class="{ filled: !!this.value }" @click="open">
            <div>
                <template v-if="!value">
                    <span class="fi fi-like"></span>
                </template>
                <template v-else>
                    <span>{{ value }}</span>
                </template>
            </div>
            <div>
                {{ name }}
            </div>

        </div>

        <transition>
            <div v-if="show" class="popup">
                <div class="popup-outer">
                    <div class="popup-inner">
                        <div class="variants">
                            <div class="variant" v-for="(variant, index) in  variants" :key="index"
                                @click="set(variant.value)"
                                v-firework="{ html: variant.value, disabled: this.value == variant.value }">
                                {{ variant.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div v-if="show" class="bk" @click="close"></div>
    </div>
</template>

<script>
export default {
    name: "sbs-control-reaction",
    components: {
    },
    emits: ["update:modelValue", "change"],
    data() {
        return {
            value: this.modelValue,
            show: false,

            variants: [
                {
                    value: "👍",
                    name: this.$t('controls.reaction.variants.like'),
                },
                {
                    value: "😁",
                    name: this.$t('controls.reaction.variants.laugh'),
                },
                {
                    value: "😍",
                    name: this.$t('controls.reaction.variants.admire'),
                },
                {
                    value: "😲",
                    name: this.$t('controls.reaction.variants.urprised'),
                },
                {
                    value: "🤔",
                    name: this.$t('controls.reaction.variants.thought_about_it'),
                },
                {
                    value: "🥺",
                    name: this.$t('controls.reaction.variants.sad'),
                },
                {
                    value: "😡",
                    name: this.$t('controls.reaction.variants.angry'),
                },
                {
                    value: "🥳",
                    name: this.$t('controls.reaction.variants.congratulations'),
                },
                {
                    value: "😢",
                    name: this.$t('controls.reaction.variants.regret'),
                },
                {
                    value: "😞",
                    name: this.$t('controls.reaction.variants.disappointed'),
                },

            ]

        };
    },
    props: {
        modelValue: {
            type: [String, Boolean],
            default() {
                return "";
            },
        }
    },

    computed: {

        name() {
            let variant = this.variants.find(v => v.value == this.value);
            if (!variant) {
                return this.$t('controls.reaction.like');
            }
            return variant.name;
        },


    },

    methods: {
        open() {
            this.show = true;
        },

        close() {
            this.show = false;
        },

        set(value) {
            if (value == this.value) {
                this.value = "";
            } else {
                this.value = value;
            }
            this.$emit("update:modelValue", this.value);
            this.$emit("change", this.value);

            if (!this.value) {
                this.close();
            } else {

                //ждём окончания анимации
                setTimeout(() => {
                    this.close();
                }, 500);
            }


        }
    }
}
</script>

<style lang="scss">
.sbs-control-reaction {
    display: inline-block;
    column-gap: 10px;
    align-items: center;
    position: relative;


    .like {
        padding: 5px 0px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        flex-grow: 1;
        color: var(--color-chat-fio);
        line-height: 1;

        &.filled {
            color: var(--color-text-primary);
        }

        &>div:first-child {
            line-height: 0;
        }
    }

    .popup {

        position: absolute;

        left: -25px;
        min-width: 190px;
        max-width: 80vw;
        text-align: left;
        z-index: 100;

        bottom: 120%;
        opacity: 1;

        cursor: default;

        transition: opacity 0.2s ease-out, top 0.2s ease, bottom 0.2s ease;


        &.v-enter-from {
            opacity: 0;
            bottom: 200%;
        }

        &.v-leave-to {
            opacity: 0;
            bottom: 200%;
        }

        .popup-outer {
            z-index: 2;
            position: relative;
            margin-bottom: 2px;

            border-radius: 25px;

            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: -7px;
                left: 31px;
                width: 14px;
                height: 14px;
                background-color: var(--color-control-background);

                box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
                transform: rotate(45deg);

                z-index: 1;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: var(--color-control-background);
                z-index: 2;
                border-radius: 25px;
            }

            .popup-inner {
                position: relative;
                z-index: 3;
                padding: 15px 0px 15px 0px;
            }

            .variants {
                display: flex;
                align-items: center;
                column-gap: 5px;
                font-size: 25px;

                overflow: auto;
                padding-right: 15px;
                padding-left: 15px;

                &::-webkit-scrollbar {
                    display: none;
                }

                .variant {
                    padding: 2px;
                    cursor: pointer;
                }
            }
        }
    }

    .bk {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 99;
    }
}
</style>