import {HmsPush, HmsLocalNotification} from '@hmscore/ionic-native-hms-push'
import { Capacitor } from '@capacitor/core';

import bus from '@/plugins/bus'
import store from '@/store'
import rest from "@/plugins/rest"
import errorListiner from '@/plugins/error.listiner'
import pushGoogle from '@/plugins/capacitor/google.push'

const push = {

    currentTopic: "",

    init() {

        this.register();
        //удаляем ненужные каналы 
        this.deleteDefaultChannels();

        //получение data-уведомления (придётся от них отказаться - нужно писать свой сервис для отлавливания таких уведомлений)
        window.HmsPushEvent.onRemoteMessageReceived((result) => {

            let data = typeof result.msg.data === 'object' ? result.msg.data: JSON.parse(result.msg.data);

            //покажем сообщение в области уведомлений
            bus.emit('SBS_NOTIFICATION_E_SHOW', data);
            //отправим событие всем подписанным компонентам
            bus.emit('SBS_PUSH_E_RECEIVE', data);
        });

        //открытие приложения по клику на уведомение
        window.HmsPushEvent.onNotificationOpenedApp((result) => {

            //создаём задержку, чтобы App успел смонтироваться и инициализироваться
            setTimeout(() => {
                //если было смешанное push (notification+data), то данные сразу в extras
                //иначе если было data push, то данные указаны в extras.notification.data
                let data = result.extras.notification && result.extras.notification.data ? result.extras.notification.data : result.extras
                pushGoogle.onPushClick(data);
            }, 300);
        });
        
    },

    /**
     * Производит регистрацию и проверку доступа
     */
    async register() {

        HmsPush.setBackgroundFile('public/push_background/hms.js');

        //инициализация
        HmsPush.init().then(() => {

            //получаем токен
            HmsPush.getToken().then((result) => {

                //обновляем токен в приложении
                this.onTokenRefresh(result);
            });

        }).catch(error => {
            errorListiner.onError(error);
            console.error("HmsPush int error")
            console.error(error)
        })
        
        //подписываемся на запрос удаления токена с сервера
        bus.on("SBS_PUSH_E_DELETE_TOKEN_FROM_SERVER", this.deleteTokenFromServer);
        //подписываемся на запрос сохранения токена на сервере
        bus.on("SBS_PUSH_E_SAVE_TOKEN_TO_SERVER", this.saveTokenToServer);
    },

    /**
    * Обработка изменения токена
    */
    onTokenRefresh(token) {

        let oldToken = store.getters.getPushToken;

        //сохраним токен в текущем состоянии приложения
        store.commit('setPushToken', token);

        //сохраним токен на сервере
        this.saveTokenToServer(oldToken);

    },

    /**
    * Сохраняет токен на сервере
    */
    saveTokenToServer(oldToken = null) {

        //сохраняем только в случае если пользователь авторизован и имеется FCM токен
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.add", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken,
                    oldToken: oldToken,
                    platform: Capacitor.getPlatform(),
                    type: "HMS"
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.errorText
                        });
                    }

                })
                .catch(() => {
                    //в тихом режиме не обрабатываем ошибку
                });
        }

    },

    /**
    * Удаление
    */
    deleteTokenFromServer() {

        //только если токен имеется и пользователь авторизован
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.delete", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка удаления push токена: " +data.errorText
                        });
                    }

                })
        }

    },

    /**
     * Удаяет каналы, созданные по умолчанию
     */
    deleteDefaultChannels() {

        HmsLocalNotification.getChannels().then(result => {
            if(!result) {
                return;
            }
            result.forEach(channelId => {
                if(channelId.includes("huawei-hms-cordova-push-channel")) {
                    HmsLocalNotification.deleteChannel(channelId);
                }
            });
        })
        
    }

}

export default push;