import { LocalNotifications } from '@capacitor/local-notifications'

import bus from '@/plugins/bus'
import moment from "moment";
import googlePush from '@/plugins/capacitor/google.push'
import store from '@/store'

const instance = {

    init() {
        
        //подписываемся на событие необходимости показа локального уведомления
        bus.on('SBS_NOTIFICATION_E_SHOW', async (data) => {

            let status = await LocalNotifications.checkPermissions();
            if(status.display == 'prompt' || status.display == 'prompt-with-rationale') {
                status = await LocalNotifications.requestPermissions();
            }
            if (status.display !== 'granted') {
                return;
            }

            let arSchedule = {
                id: parseInt(moment().format("X")),
                title: data.title,
                body: data.body,
                extra: data
            };
            if (data.image) {
                arSchedule["Attachments"] = [
                    {
                        id: data.image,
                        url: data.image
                    }
                ];
            }

            //если передали ИД канала
            if(data.channel_id) {
                arSchedule.channelId = data.channel_id;
            }

            let ignore = false;
            
            //если пуш о новом сообщении в чате
            if(data.type == "request_message") {
                //если сообщение уже просмотрено
                let stask = store.getters.getReadMessagesStack;
                if(stask.includes(data.messageId)) {
                    ignore = true;
                }
            }

            if(!ignore) {
                LocalNotifications.schedule({ notifications: [arSchedule] });
            }

            //обновление счётчика у иконки приложения (а то локальная нотификация сбрасывает кол-во у иконки приложения)
            bus.emit("SBS_BADGE_E_SET", store.getters.getBadgetCount);

        });

        //обработка действия по уведомлению
        LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
            googlePush.onPushClick(event.notification.extra);
        });
    }

}

export default instance;