<template>

    <sbs-sheet-base v-model:visible="visibleInternal" maxWidth="960px" :class="this.class" :noStretch="noStretch"
        :onlyHeaderSwipe="onlyHeaderSwipe" :maxHeight="maxHeight" :minHeight="minHeight">
        <template v-slot:body>
            <slot name="body"></slot>
        </template>

    </sbs-sheet-base>


</template>

<script>

import sbsSheetBase from '@/components/sheet.base.vue'
import { mapGetters } from "vuex";

export default {
    name: "sbs-sheet",
    emits: ["update:visible"],
    components: {
        sbsSheetBase,
    },
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        minHeight: {
            type: Number,
            default() {
                return 40;
            },
        },
        maxHeight: {
            type: Number,
            default() {
                return 90;
            },
        },
        class: {
            type: String,
            default() {
                return "";
            },
        },
        noStretch: {
            type: Boolean,
            default() {
                return false
            }
        },
        onlyHeaderSwipe: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {
        ...mapGetters({
            safeArea: "getSafeArea",
            platform: "getPlatform"
        }),
        paddingBottom() {
            if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
                return this.safeArea.bottom;
            }

            return 0;
        }
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        }
    },
    methods: {
        /**
         * Обработка открытия
         */
        onOpen() {

            this.watchVisible = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?sheetOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },
    },

    /**
     * Обработка создания экземпляра
     */
    created() {
        //если создаётся уже открытым
        if (this.visible) {
            this.onOpen();
        }
    }
}
</script>

<style lang="scss"></style>