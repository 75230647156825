<template>
  <sbs-view :class="'sbs-v-requests-filter'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right>
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onSettingsClick">
            <span class="icon fi fi-settings"></span>
          </div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <div class="h1">Фильтр</div>

      <template v-if="loading">
        <sbs-loader-inline />
      </template>
      <template v-else>

        <div class="filters">
          <sbs-control-filter label="Оборудование" method="equipment.list" v-model="filter.equipment" />
          <sbs-control-filter label="Приоритет" method="dictionary.request.priorities.list" v-model="filter.priority" />
          <sbs-control-filter label="Тип" method="dictionary.request.type.list" v-model="filter.type" />
          <sbs-control-filter label="Статус" method="dictionary.request.statuses.list" v-model="filter.status" />
          <sbs-control-filter label="Подразделение - исполнитель" method="dictionary.departments.list"
            v-model="filter.responsibleDepartment" :params="{
    filter: [
      {
        active: 'Y',
      },
    ],
  }" />
          <sbs-control-filter label="Исполнитель" method="users.list" v-model="filter.responsibleUser"
            :params="responsibleUserParams" />

          <sbs-control-filter label="Автор" method="users.list" v-model="filter.createdBy" />
          <sbs-control-filter label="Подразделение автора" method="dictionary.departments.list"
            v-model="filter.createdDepartment" :params="{
    filter: [
      {
        active: 'Y',
      },
    ],
  }" />
          <sbs-control-filter-dates label="Дата создания" v-model="filter.created" />

          <sbs-control-filter-dates label="Крайний срок" v-model="filter.deadline" />

          <sbs-control-filter label="Просрочена" :items="boolList" v-model="filter.overdue" />

          <sbs-control-filter v-if="statistics.ratings.length > 0" label="Оценка" :items="statistics.ratings"
            v-model="filter.rating" />
        </div>

        <div class="h2">Сортировка</div>

        <sbs-control-sort v-model="sort" :fields="sortFields" />



      </template>

      <sbs-popup v-model:show="showSettings" :loading="actionsLoading">

        <div class="actions">
          <!-- Сохранить как -->
          <div class="action" @click="onSaveAsClick">
            <span class="icon fi fi-save"></span>
            <div class="text">Сохранить как</div>
          </div>

          <!-- Сохранить -->
          <div v-if="savedFilterId > 0" class="action" @click="saveFilter">
            <span class="icon fi fi-save"></span>
            <div class="text">Сохранить</div>
          </div>

          <!-- Удалить -->
          <div v-if="savedFilterId > 0" class="action" @click="deleteFilter">
            <span class="icon fi fi-close"></span>
            <div class="text">Удалить</div>
          </div>

        </div>

      </sbs-popup>

      <sbs-popup v-model:show="showSaveAs" :loading="filterSaving">

        <div ref="filter_save_form">

          <sbs-control-input v-model="filterName" type="text" label="Название" :required="true" />

          <div class="btn btn-block btn1 btn-filter-add" @click="addFilter">Сохранить</div>
        </div>

      </sbs-popup>
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <div class="container">
          <div class="page-padding">
            <div class="row">
              <div class="col-6">
                <div class="btn btn-block btn2" @click="clear">Сбросить</div>
              </div>
              <div class="col-6">
                <div class="btn btn-block btn1" @click="apply">Применить</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlFilter from "@/components/controls/filter";
import sbsControlFilterDates from "@/components/controls/filter.dates";
import sbsPopup from "@/components/popup.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlSort from "@/components/controls/sort.vue";

import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-requests-filter",
  components: {
    sbsView,
    sbsHeader,
    sbsControlFilter,
    sbsControlFilterDates,
    sbsPopup,
    sbsControlInput,
    sbsLoaderInline,
    sbsControlSort
  },
  data() {
    return {
      filter: [],
      sort: {},
      reset: 0,
      loading: false,
      hideMenu: false,

      statistics: {
        ratings: []
      },

      showSettings: false,
      showSaveAs: false,
      filterName: "",
      filterSaving: false,
      actionsLoading: false,

      boolList: [
        {
          "id": "Y",
          "name": "Да"
        },
        {
          "id": "N",
          "name": "Нет"
        },
      ],

      sortFields: [
        {
          id: "ID",
          name: "Дата создания",
          order: "desc"
        },
        {
          id: "DATE_UPDATE",
          name: "Дата изменения"
        },
        {
          id: "DATE_STATUS_UPDATE",
          name: "Дата изменения статуса"
        },
        {
          id: "DATE_CLOSE",
          name: "Дата завершения"
        },
        {
          id: "STATUS.SORT",
          name: "Статус"
        },
        {
          id: "PRIORITY.SORT",
          name: "Приоритет"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      tab: "getRequestsTab",
      filterKey: "getRequestsTabFilterKey"
    }),

    globalFilter() {
      return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
    },

    globalSort() {
      return this.$store.getters.getSort("REQUEST-" + this.filterKey);
    },

    savedFilterId() {
      //если код вкладки подразумевает сохранённый фильтр
      if (this.tab.includes("saved-filter")) {
        let id = this.tab.replace(/[^0-9]/g, "");
        return id;
      }

      return 0;
    },

    responsibleUserParams() {
      let params = {};

      if (this.filter.responsibleDepartment && this.filter.responsibleDepartment.length > 0) {
        params.filter = {
          department_id: this.filter.responsibleDepartment.map(i => i.id)
        }
      }

      console.log(params);
      return params;

    }
  },

  methods: {

    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        ratings: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["rating", "cnt"],
            group: ["rating"],
            order: { "rating": "asc" },
          },
        },

      }
    },

    /**
     * Загрузка данных
     */
    loadData() {

      this.loading = true;

      rest.batch(this.getBatchRequests())
        .then((data) => {
          this.processResponse(data);
        })
        .finally(() => {
          this.loading = false;
        });

    },

    /**
     * Обработка результата
    */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "ratings") {
            this.setRatings(data.items);
          }

        } else {

          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;

        }

      }
    },

    /**
     * Устанавливает статусы заявок
     */
    setRatings(items) {
      this.statistics.ratings = items.map(item => {
        return { id: item.rating, name: item.rating ? item.rating : "не задана", count: item.cnt }
      });

    },

    setDefaults() {
      this.filter = {
        equipment: [],
        priority: [],
        type: [],
        status: [],
        responsibleDepartment: [],
        responsibleUser: [],
        createdBy: [],
        createdDepartment: [],
        created: [],
        deadline: [],
        rating: [],
        overdue: []
      };

      this.sort = {
        ID: "desc"
      };
    },

    apply() {
      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "REQUEST-" + this.filterKey,
        value: this.filter
      });

      //сохраняем сортировку в текущем состоянии
      this.$store.commit("setSort", {
        name: "REQUEST-" + this.filterKey,
        value: this.sort
      });

      //переход на предыдущий экран
      this.$router.go(-1);
    },

    /**
     * Сбросить фильтр
     */
    clear() {
      //сбрасываем фильтр
      this.setDefaults();

      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "REQUEST-" + this.filterKey,
        value: this.filter
      });

      //сохраняем сортировку в текущем состоянии
      this.$store.commit("setSort", {
        name: "REQUEST-" + this.filterKey,
        value: this.sort
      });

      //переход на предыдущий экран
      this.$router.go(-1);
    },

    /**
     * Приводит данные экрана в соответствии с глобально установленным фильтром
     */
    refresh() {
      for (let code in this.filter) {
        if (this.globalFilter[code]) {
          this.filter[code] = this.globalFilter[code];
        } else {
          this.filter[code] = this.filter[code] instanceof Array ? [] : "";
        }
      }

      if (this.globalSort && Object.keys(this.globalSort).length > 0) {
        this.sort = this.globalSort;
      }
    },

    /**
     * Клик по иконке "Настройки"
     */
    onSettingsClick() {
      this.showSettings = true;
    },

    /**
     * Клик по "Сохранить как"
     */
    onSaveAsClick() {
      this.showSettings = false;
      this.showSaveAs = true;
    },

    /**
     * Добавление фильтра
     */
    addFilter() {

      if (!validator.check({ container: this.$refs.filter_save_form })) {
        return;
      }

      this.filterSaving = true;

      //выполняем запрос
      rest
        .call("user.filter.add", {
          method: "post",
          data: {
            fields: {
              name: this.filterName,
              type: "REQUEST",
              filter: this.filter,
              sort: this.sort
            }
          },
        })
        .then((data) => {
          if (data.success) {
            this.showSaveAs = false;
            //показываем сообщение об успехе
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно сохранён", type: "success" });

            //добавим сохранённый фильтр в глобальное состояние
            let item = {
              id: data.id,
              name: this.filterName,
              type: "REQUEST",
              filter: this.filter
            }
            this.$store.dispatch("addUserFilter", item);


            let vm = this;
            let isWasCommon = this.filterKey == "common";
            setTimeout(() => {

              //поменяем текущую вкладку в списке заявок на новых сохранённый фильтр
              vm.$store.commit("setRequestsTab", "saved-filter-" + item.id);

              //если сохранение запустили с системной вкладки заявкок (Активное, Мне и пр.)
              if (isWasCommon) {
                //то сбросим у них фильтр
                vm.$store.commit("setFilter", {
                  name: "REQUEST-common",
                  value: {}
                });

                //то сбросим у них сортировку
                vm.$store.commit("setSort", {
                  name: "REQUEST-common",
                  value: {}
                });
              }

              //переход на предыдущий экран
              vm.$router.go(-1);

            }, 100);

          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.filterSaving = false;
        });


    },

    /**
     * Сохранение фильтра
     */
    saveFilter() {

      this.actionsLoading = true;

      //выполняем запрос
      rest
        .call("user.filter.update", {
          method: "post",
          data: {
            id: this.savedFilterId,
            fields: {
              filter: this.filter,
              sort: this.sort
            }
          },
        })
        .then((data) => {
          if (data.success) {
            this.showSettings = false;
            //показываем сообщение об успехе
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно сохранён", type: "success" });
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.actionsLoading = false;
        });

    },

    /**
     * Удаление фильтра
     */
    deleteFilter() {

      this.actionsLoading = true;

      //выполняем запрос
      rest
        .call("user.filter.delete", {
          method: "post",
          data: {
            id: this.savedFilterId
          },
        })
        .then((data) => {
          if (data.success) {
            this.showSettings = false;
            //показываем сообщение об успехе
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Фильтр успешно удалён", type: "success" });

            //удаляем из глобального состояния сохранённый фильтр
            this.$store.commit("removeUserFilter", this.savedFilterId);
            //устанавливаем активной вкладку "Активные"
            this.$store.commit("setRequestsTab", "active");
            //переход на предыдущий экран
            this.$router.go(-1);

          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    }



  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    this.loadData();
    this.setDefaults();
    this.refresh();

    //подписваемся на события
    bus.on("SBS_REQUEST_FORM_E_SAVE", this.loadData);
    bus.on("SBS_REQUEST_E_UPDATED", this.loadData);
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.refresh();
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setDefaults();
      vm.refresh();
    });
  },
};
</script>

<style lang="scss">
.sbs-v-requests-filter {
  .filters {
    margin-bottom: 30px;
  }

  .buttons {
    padding: 20px 0px;
  }
}
</style>