<template>
  <div class="sbs-control-photos form-group  form-group-label filled">

    <template v-if="label != ''">
      <div class="floating-label" v-on:click="onLabelClick">
        {{ label }}
        <span v-if="required && needShowRequired">*</span>
      </div>
    </template>

    <div class="row" :class="{ 'justify-content-center': photos.length == 0 }">
      <div class="col-6 col-lg-3" v-for="photo in photos" :key="photo.id">
        <div class="photo" :style="{
      backgroundImage: photo.data
        ? 'url(data:image/jpeg;base64,' + photo.data + ')'
        : 'url(' + photo.preview + ')',
    }">
          <div class="delete" @click="deletePhoto(photo.id)">
            <span class="ic fi fi-close"></span>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-3">
        <div class="photo add-photo">
          <div class="camera" @click="getPicture">
            <div class="wrap">
              <div class="text-center">
                <span class="ic fi fi-camera"></span>
                <div>Добавить фото</div>
              </div>
            </div>
          </div>
          <div class="gallery" @click="getPictureFromGallery">
            <div class="wrap">
              <div class="text-center">
                <span class="ic fi fi-photo-library"></span>
                <div>Выбрать фото</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";
import moment from "moment";
import resizebase64 from "@/plugins/resizebase64";

export default {
  name: "sbs-control-photos",
  emits: ["update:modelValue"],
  data() {
    return {
      photos: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRequired: {
      type: Boolean,
      default() {
        return true;
      },
    },
    disableGallery: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },

  computed: {
    needShowRequired() {
      return this.required && this.showRequired;
    },
  },

  watch: {
    modelValue: function () {
      this.photos = this.modelValue
    }
  },

  methods: {
    /**
     * Получает фото с камеры
     */
    getPicture() {
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPicture,
      });
    },

    /**
     * Получает фото с галереи
     */
    getPictureFromGallery() {

      if (this.disableGallery) {
        //показываем сообщение об ошибке
        bus.emit("SBS_MESSAGE_E_SHOW", { message: "Для данного поля выбор фото из галлереи отключен." });
        return;
      }

      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPicture,
        photoLibrary: true,
      });
    },

    /**
     * Обработка получения фото с камеры
     */
    async onGetPicture(imageData) {
      var newImg = await resizebase64(
        "data:image/jpeg;base64," + imageData,
        1200,
        1200
      );

      let item = {
        id: moment().format("x"),
        data: newImg.replace("data:image/jpeg;base64,", ""),
      };
      this.photos.push(item);
      this.$emit("update:modelValue", this.photos);
    },

    /**
     * Удаление фото
     */
    deletePhoto(id) {
      this.photos = this.photos.filter((p) => p.id != id);
      this.$emit("update:modelValue", this.photos);
    },
  },
};
</script>

<style lang="scss">
.sbs-control-photos {
  margin-top: 30px;

  .photo {
    position: relative;
    margin-bottom: 20px;
    height: 0px;
    padding-top: 177%;
    background-color: #000;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    .delete {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--color-btn1-background);
      cursor: pointer;

      .ic {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-btn1-text);
      }
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .add-photo {
    background-color: transparent !important;
    border: 1px solid currentColor;

    .camera {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 50%;
      border-block: 1px dashed currentColor;
    }

    .gallery {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 50%;
    }

    .wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ic {
      font-size: 50px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
}
</style>