<template>

    <div class="sbs-edu-post-feedback">
        <div class="picture-wrap" v-if="item.picture">
            <div class="picture preloaded" v-lazy:background-image="item.picture.preview"
                @click="openPhoto(item.picture.id)">

                <div class="preload" v-if="item.picture.preload"
                    :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
                </div>

                <div class="loader">
                    <sbs-loader-inline />
                </div>
            </div>
        </div>

        <div class="author-date">
            <div class="date">{{ formatDate() }}</div>
            <div class="author">
                <div>
                    <div class="avatar">
                        <div v-if="!item.user_photo" class="initials">
                            {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                        </div>
                        <div v-else class="photo" :style="{
            backgroundImage: 'url(' + item.user_photo.preview + ')',
        }"></div>
                    </div>
                </div>
                <div>
                    <div class="fio">
                        {{ item.user_last_name }} {{ item.user_name }}
                    </div>
                </div>
            </div>
        </div>

        <h1>{{ item.name }}</h1>

        <template v-if="!result">
            <br />
            <h2>{{ $t("education.post.feedback.title") }}</h2>
            <br />
            <div class="form" ref="form">

                <sbs-control-textarea v-model="message" :label="$t('education.post.feedback.comment')" :required="true"
                    :rows="5">
                    <template v-if="isNativePlatform" v-slot:icon>
                        <div class="icon" @click="speech">
                            <span class="fi fi-mic"></span>
                        </div>
                    </template>
                </sbs-control-textarea>

                <sbs-control-photos v-model="photos" :label="$t('education.post.feedback.photo')" />

                <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
                    {{ $t('education.post.feedback.send') }}
                    <div v-if="saving" class="btn-loader"></div>
                </div>


            </div>
        </template>

        <template v-if="trainingId > 0 && result">
            <sbs-teleport-wrapper :to="'#' + this.footerId">
                <div class="sbs-edu-post-post-btns">
                    <div class="container">
                        <div class="btn btn1 btn-block" @click="onNextClick" :class="saving ? 'wait' : ''" v-ripple.300>
                            {{ $t("common.next") }}
                            <div v-if="saving" class="btn-loader"></div>
                        </div>
                    </div>
                </div>
            </sbs-teleport-wrapper>
        </template>

    </div>


    <template v-if="result && item.comments_enabled == 'Y'">
        <br />
        <sbs-education-post-comments :postId="item.id" :private="item.comments_private == 'Y'" />
    </template>

</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostComments from "@/components/education/post.comments.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import moment from "moment";
import utils from "@/plugins/utils";
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import validator from "@/plugins/validator";

import { mapGetters } from "vuex";

export default {
    name: "sbs-education-post-feedback",
    components: {
        sbsLoaderInline,
        sbsControlTextarea,
        sbsControlPhotos,
        sbsEducationPostComments,
        sbsTeleportWrapper
    },
    emits: ["complete"],
    data() {
        return {
            saving: false,
            message: "",
            photos: [],
            result: this.item.result ? this.item.result : false

        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        footerId: {
            type: String,
            default() {
                return ""
            }
        }
    },

    computed: {

        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),

        gallery() {
            let images = [];

            if (this.item.picture.preview) {
                images.push({
                    src: this.item.picture.full ? this.item.picture.full : this.item.picture.src,
                    type: "image",
                    width: this.item.picture.width,
                    height: this.item.picture.height,
                    thumb: this.item.picture.preview ? this.item.picture.preview : this.item.picture.src,
                    caption: this.item.name,
                    id: this.item.picture.id,
                });
            }

            return images;
        },


    },

    methods: {

        formatDate() {
            let date = moment(this.item.publication_date, "DD.MM.YYYY HH:mm:ss");
            let days = moment().diff(date, 'days');
            if (days <= 3) {
                return date.fromNow();
            }
            return date.format("DD MMMM YYYY");
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },

        /**
         * Рапознование голоса для вставки текста сообщения
         */
        speech() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.message = text;
                },
            });
        },

        /**
         * Сохранение комментария
         */
        save() {

            //проверка введённых данных
            if (!validator.check({ container: this.$refs.form })) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            //поля для отправки
            let fields = {
                post_id: this.item.id,
                comment: this.message,
                files: []
            };

            //добавлям файлы
            if (this.photos) {
                this.photos.forEach(photo => {
                    if (photo.data) {
                        fields.files.push(photo.data);
                    }
                })

            }

            let formData = new FormData();
            for (let code in fields) {
                if (fields[code] instanceof Array) {
                    for (let i = 0; i < fields[code].length; i++) {
                        formData.append(code + "[]", fields[code][i]);
                    }
                } else {
                    formData.append(code, fields[code]);
                }

            }

            //сохранение сообщения
            rest
                .call("education.post.comment.add", {
                    method: "POST",
                    data: formData,
                    timeout: 0,
                })
                .then((data) => {
                    if (data.success) {
                        //сохраняем результат
                        this.saveResult();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                });
        },

        /**
         * Сохранение результата
         */
        saveResult() {

            let fields = {
                id: this.item.id,
                training_id: this.trainingId,
                status: "S",
                data: {}
            };

            this.result = fields;

            //запрашиваем данные
            rest
                .call("education.post.result", {
                    method: "post",
                    data: fields,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {
                        //генерим событие успешного сохранения результата
                        bus.emit("SBS_EDU_POST_RESULT_E_SAVE", fields);
                    }
                });
        },

        /**
         * Клик Далее - в режиме тренировки
         */
        onNextClick() {

            this.$emit("complete");

        },

    }
}

</script>

<style lang="scss">
.sbs-edu-post-feedback {
    position: relative;

    h1 {
        color: var(--color-text-primary);
        text-transform: none;
        font-size: 21px;
        font-weight: 500;
    }

    @media (max-width: 767px) {
        .picture-wrap {
            margin: 0px -20px;
        }

    }

    .picture {
        height: 0px;
        padding-top: 60%;
        background-size: cover;
        background-position: center center;
        margin-bottom: 10px;
        background-color: var(--color-block-background);
    }

    .author-date {
        margin-bottom: 10px;

        .date {
            font-size: 12px;
            color: var(--color-chat-fio);
            float: right;
        }
    }

    .author {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .avatar {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--color-chat-fio);

            .initials {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-chat-background);
            }

            .photo {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-size: cover;
            }
        }

        .fio {
            font-size: 12px;
            font-weight: 500;
            color: var(--color-chat-fio);
        }


    }

}

.sbs-edu-post-feedback-btns {
    padding: 15px 0px;

}
</style>