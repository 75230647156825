<template>
    <sbs-view :class="'sbs-v-edu-posts'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">

        <template v-slot:header>
            <sbs-header :showBackBtn="false">
                <template v-slot:center>
                    <div class="title">{{ $t('education.posts.title') }}</div>
                </template>
                <template v-slot:right>

                    <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onSearchClick">
                        <span class="icon fi fi-search"></span>
                    </div>
                    <div class="action" :class="{ on: filtered }" v-ripple.300="'rgba(100, 100, 100, 0.15)'"
                        @click="onFilterClick">
                        <span class="icon fi fi-filter"></span>
                    </div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <!-- Если список пуст-->
            <template v-if="items.length == 0 && !loading">

                <sbs-empty :title="$t('education.posts.empty.title')"
                    :text="filtered ? $t('education.posts.empty.filtered_text') : $t('education.posts.empty.text')" />

            </template>

            <template v-else>
                <div class="items row">

                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" v-for="item in items" :key="item.id">
                        <sbs-education-post-item :item="item" class="item" />
                    </div>

                </div>
            </template>

            <div ref="pager"></div>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>


        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostItem from "@/components/education/post.item.vue"
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import utils from "@/plugins/utils";

export default {
    name: "sbs-v-education-posts",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEducationPostItem,
        sbsEmpty
    },
    data() {
        return {
            hideMenu: false,

            loading: true,
            items: [],
            pager: {
                size: 18,
                page: 1,
                cnt: 1,
            },
        };
    },

    computed: {

        filter() {
            return this.$store.getters.getFilter("EDUCATION_POSTS");
        },

        filterPrepared() {
            let f = {};

            if (!this.filter || Object.keys(this.filter).length == 0) {
                return f;
            }

            let i = 0;
            for (let key in this.filter) {
                let value = this.filter[key];
                if (value instanceof Array) {
                    if (value.length > 0) {
                        if (key == "publication_date") {
                            f[i] = utils.getFilterForDates(this.filter[key], key);
                        } else {
                            f[key] = value.map((v) => v.id);
                        }

                    }
                    i++;
                }

            }

            return f;
        },

        filtered() {
            return Object.keys(this.filterPrepared).length > 0;
        },

    },

    watch: {
        filterPrepared: {
            handler() {
                this.items = [];
                this.loadData();
            },
            deep: true,
        },
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            //покажем экран загрузки
            this.loading = resolve ? false : true;

            //запрашиваем данные
            rest
                .call("education.post.list", {
                    method: "post",
                    data: {
                        select: ["user_photo", "user_name", "user_last_name"],
                        paging: this.pager,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        order: {
                            "publication_date": "desc"
                        },
                        filter: this.filterPrepared,
                        load_my_reactions: "Y",
                        load_reactions: "Y"
                    },
                })
                .then((data) => {
                    if (data.success) {
                        if (this.pager.page == 1) {
                            this.items = data.items;
                        } else {
                            this.items = this.items.concat(data.items);
                        }
                        this.pager = data.pager;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.pager.page = 1;
            this.loadData(resolve);
        },

        /**
         * Обработка срола списка
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (!this.loading && this.pager.page < this.pager.cnt) {
                    this.pager.page++;
                    this.loadData();
                }
            }
        },

        /**
         * Клик по иконке "Фильтр"
         */
        onFilterClick() {
            this.$router.push({ name: "education-posts-filter" });
        },

        /**
         * Клик по иконке "Поиск"
         */
        onSearchClick() {
            this.$router.push({ name: "education-posts-search", });
        },
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },


    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}
</script>

<style lang="scss">
.sbs-v-edu-posts {

    @media (min-width: 768px) {
        .items {
            margin: 0px -10px;

            >div {
                padding: 0px 10px;
            }

            .item {
                min-height: calc(100% - 20px);
            }
        }
    }
}
</style>