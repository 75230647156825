
class SnowList {

    constructor (offsetParent,x,y,props) {
        //Store snow objects
        this.snowFlakes = [];
        this.x = x;
        this.y = y;
        this.parent = offsetParent;
        this.props = props;

        this.addShow();
        
        requestAnimationFrame(this.refresh.bind(this))
    }

    addShow() {

        var duration = 500;

        let show1 = new Snow(this.parent,this.x, this.y, "#ff6800", duration, this.props.html );
        this.snowFlakes.push(show1);

        var _this = this;
        setTimeout(function() {
            show1.remove();
            _this.snowFlakes.splice(0,1);
        },duration);

        if(this.snowFlakes.length < 10) {
            setTimeout(function() {
                _this.addShow.call(_this);
            },30);
        }

    }

    refresh(time) {

        for ( let i = this.snowFlakes.length-1; 0 <= i; i-- ) {
            this.snowFlakes[ i ].update(time);
        }

        if(this.snowFlakes.length > 0) {
            requestAnimationFrame(this.refresh.bind(this))
        }

    }
}

class Snow {
    constructor (parent, x, y, c, d, html) {
        this.elm = document.createElement("div");
        this.parent = parent;
        this.parent.appendChild( this.elm );
        this.sty = this.elm.style;

        this.elm.innerHTML = html;


        this.x1        = x;
        this.y1        = y;
        this.color    = c;
        this.duration = d;

        this.sty.position = "absolute";
        this.sty.left     = this.x1 + "px";
        this.sty.top      = this.y1 + "px";
        this.sty.zIndex = 10000;
        this.sty.pointerEvents = "none";

        let size = this.randomInt( 8, 12 );
        this.sty.fontSize           = size + "px";
        //this.sty.width           = size + "px";
        //this.sty.height          = size + "px";

        let opacity = this.randomInt( 5, 10 );
        this.sty.opacity         = opacity / 10;//from 0.5 to 1.0
        //this.sty.borderRadius    = "50%";
        //this.sty.backgroundColor = this.color;
        this.sty.color = this.color;

        this.vectorX = this.randomInt( -8, 8 );
        this.vectorY = this.randomInt( -8, 8 );
        this.x2 = this.x1 + 10 * this.vectorX;
        this.y2 = this.y1 + 10 * this.vectorY;

        this.start = performance.now();
    }

    update (time) {


        var t = time - this.start;
        var x = t * (this.x2 - this.x1) / this.duration + this.x1;
        var y = t * (this.y2 - this.y1) / this.duration + this.y1;

        //гравитация
        this.y2 += 2 * (1- (this.duration - t)/ this.duration);

        if(t <= this.duration) {
            this.sty.opacity = (this.duration - t)/ this.duration;
        } else {
            this.sty.opacity = 0;
        }
        
        this.sty.left = x + "px";
        this.sty.top  = y + "px";
    }

    //Generate a random integer from min to max
    randomInt ( min, max ) {
        return Math.floor( Math.random() * (max-min+1) + min );
    } 

    remove() {
        this.parent.removeChild( this.elm );
    }
}

var Firework = {
    beforeMount: function(el, binding){

        // Default values.
        var props = {
            event: 'click',
            html: binding.value && binding.value.html ? binding.value.html : "⭐"
        };

        if(binding.value.disabled) {
            return;
        }

        el.addEventListener(props.event, function(e) {

            //получаем родителя по позиции сами,
            //так как jquery offsetPArent не работает на скрытых элементах
            //а сюда клик может дойти как раз когда уже кнопка по которой кликнули - скрылась
            var offsetParent = getOffsetParrent(el);

            var viewportOffset = offsetParent.getBoundingClientRect();

            var x = e.pageX - viewportOffset.left;
            var y = e.pageY - viewportOffset.top;


            new SnowList(offsetParent,x,y,props);

        });

   }
};

function getOffsetParrent(el) {
    // var parent = el.parentNode;
    // if(!parent) {
    //     return window;
    // }
    // console.log(parent.style.position );
    // if(parent.style.position == "relative" || parent.style.position == "absolute" || parent.style.position == "fixed") {
    //     return parent;
    // }

    // return getOffsetParrent(parent);

    return el.offsetParent;
}

export default Firework;